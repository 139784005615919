const $ = require('jquery');

$('.get-signed-url').on('click', function(e) {
    e.preventDefault();
    var linkContainer = $(this).parents('.link-container:first');
    var size = linkContainer.find('.size-spinner .filesize');
    var spinner = linkContainer.find('.size-spinner .sign-spinner');
    var checkDownload = linkContainer.find('.size-spinner .check-download');

    size.addClass('d-none');
    spinner.removeClass('d-none');

    var raiseError = function(){
        linkContainer.html('<span class="badge badge-danger">Der Download ist fehlgeschlagen</span>');
    };

    xhr = $.ajax({
        type: 'POST',
        url: $(this).attr('href'),
        success: function(msg) {
            if (typeof msg.url == 'undefined') {
                raiseError();
            } else {
                spinner.addClass('d-none');
                checkDownload.removeClass('d-none');
                window.location = msg.url;
            }
        },
        error: function(data) {
            raiseError();
        }
    });
});