const $ = require('jquery');

$('.copy-to-clipboard').on('click', function (e) {
    e.preventDefault();
    var source = $(this).parent().find('.copy-source');
    var svg = $(this).find('svg');
    var span = $(this).find('span');

    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val(source.text()).select();
    if (document.execCommand("copy")) {
        svg.removeClass('d-none');
        span.addClass('d-none');

        window.setTimeout(function() {
            svg.addClass('d-none');
            span.removeClass('d-none');
        }, 2000);
    } else {
        $(this).html('<small class="text-danger">Fehler</small>');
    }
    $temp.remove();
});

$('.entity-delete-form').on('submit', function(e) {
   if (confirm('Diese Übertragung wirklich löschen?')) {
       return true;
   }

   return false;
});