/**
 * Uppy
 */
const Uppy = require('@uppy/core');
const AwsS3 = require('@uppy/aws-s3');
const Dashboard = require('@uppy/dashboard');
const UppyGerman = require('@uppy/locales/lib/de_DE');

import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import '@uppy/dashboard/dist/style.css';

import '../css/channel_upload.css';

/**
 * jQuery
 */
var $ = require('jquery');

window.$ = $;
window.jQuery = $;

/**
 * misc
 */
import Tagify from '@yaireo/tagify';
window.Tagify = Tagify;

require('@yaireo/tagify/dist/jQuery.tagify.min');
import '@yaireo/tagify/dist/tagify.css';

const channelMeta = $('.upload-meta');
var uploadSuccess = $('#uploadSuccess');
var uploadError = $('#uploadError');
var uploadCancelBtn = $('#uploadCancel');
var isUploading = false;
var siteTitle = '';

if (channelMeta.length) {
    /**
     * Uppy
     */
    var uploadRetries = 0;
    var maxUploadRetries = 5;
    var retryDelay = 25;
    var targetUploadCount = 0;

    const uppy = new Uppy({
        debug: false,
        autoProceed: false,
        locale: UppyGerman
    });
    uppy.use(AwsS3, {
        timeout: 3600 * 1000,
        limit: 6,
        getUploadParameters(file) {
            // Send a request to our PHP signing endpoint.
            return fetch(channelMeta.data('sign-url'), {
                method: 'post',
                // Send and receive JSON.
                headers: {
                    accept: 'application/json',
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    path: (file.meta.relativePath) ? file.meta.relativePath : file.name,
                    contentType: file.type
                })
            }).then((response) => {
                // Parse the JSON response.
                return response.json();
            }).then((data) => {
                // Return an object in the correct shape.
                return {
                    method: data.method,
                    url: data.url,
                    fields: data.fields,
                    // Provide content type header required by S3
                    headers: {
                        'Content-Type': file.type
                    }
                };
            });
        }
    });

    uppy.use(Dashboard, {
        target: '#uploadTarget',
        inline: true,
        height: '611px',
        proudlyDisplayPoweredByUppy: false,
        showProgressDetails: true,
        showLinkToFileUploadResult: false,
        hideRetryButton: true,
        hideUploadButton: true,
        hideCancelButton: true,
        locale: {
            strings: {
                dropPaste: 'Dateien können über Drag/Drop oder per %{browse} hinzugefügt werden',
            }
        }
    });

    uppy.on('upload', function(){
        isUploading = true;
        siteTitle = document.title;
        uploadCancelBtn.removeClass('d-none');
        $('#uploadTarget').addClass('is-uploading');
    });

    uppy.on('cancel-all', function(){
        isUploading = false;
        document.title = siteTitle;
    });

    uppy.on('file-added', function(file){
        var blacklist = [
            '.DS_Store'
        ];

        if (blacklist.includes(file.name)) {
            uppy.removeFile(file.id);
        }
    });

    uppy.on('upload-progress', (file, progress) => {
        // file: { id, name, type, ... }
        // progress: { uploader, bytesUploaded, bytesTotal }
        // console.log(file.id, progress.bytesUploaded, progress.bytesTotal)
        var bytesTotal = 0;
        var bytesUploaded = 0;
        uppy.getFiles().forEach(function(file, index) {
            bytesTotal += file.progress.bytesTotal;
            bytesUploaded += file.progress.bytesUploaded;
        });
        var percent = Math.round(100 / bytesTotal * bytesUploaded);
        document.title = percent+'% Transfer';
    });

    uppy.on('complete', function (result) {
        document.title = siteTitle;
        if (result.failed.length > 0) {
            if (uploadRetries < maxUploadRetries) {
                uploadRetries++;
                var countdownTicks = retryDelay;
                var countdownContainer = $('#retryTimer');
                var updateCountdown = function(secondsLeft) {
                    uploadCancelBtn.addClass('d-none');
                    countdownContainer.text('('+uploadRetries+'/'+maxUploadRetries+') Upload fehlgeschlagen. Erneuter Versuch in '+secondsLeft+' Sekunden.').removeClass('d-none');
                };

                updateCountdown(countdownTicks);

                var interval = setInterval(function(){
                    countdownTicks--;
                    if (countdownTicks < 1) {
                        countdownContainer.addClass('d-none');
                        uppy.retryAll();
                        clearInterval(interval);
                        return;
                    }
                    updateCountdown(countdownTicks);
                }, 1000);
            } else {
                isUploading = false;
                uppy.getPlugin('Dashboard').closeModal();
                raiseUploadError('Der Upload ist wiederholt fehlgeschlagen. Bitte prüfe deine Internetverbindung');
            }
        } else if(targetUploadCount < 1 || result.successful.length != targetUploadCount) {
            isUploading = false;
            raiseUploadError('Unbekannter Fehler (inconsistent file count)');
        } else {
            isUploading = false;

            uploadSuccess.removeClass('d-none').addClass('d-flex');
            uploadSuccess.find('form').attr('action', channelMeta.data('verify-url')).submit();
        }
    });

    channelMeta.on('upload', function() {
        targetUploadCount = uppy.getFiles().length;
        uppy.upload();
    });

    uploadCancelBtn.on('click', function(){
        uppy.cancelAll();
        raiseUploadError('Der Upload wurde manuell abgebrochen');
    });



    /**
     * form
     */

    /** tagify **/
    var input = $('#recipientGroup input');
    var jQTagify = input.tagify({
                whitelist : [],
                addTagOnBlur: true,
                skipInvalid: true,
                pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                templates: {
                    dropdownItem( item ){
                        var mapValueTo = this.settings.dropdown.mapValueTo,
                            value = item.searchBy,
                            sanitizedValue = (value || item).replace(/`|'/g, "&#39;");

                        return `<div ${this.getAttributes(item)}
                                    class='tagify__dropdown__item ${item.class ? item.class : ""}'
                                    tabindex="0"
                                    role="option"
                                    aria-labelledby="dropdown-label">${sanitizedValue}</div>`;
                      }
                }
            });

    var tagify = input.data('tagify');
    var xhr;

    jQTagify.on('input', function(e) {
        var value = $('.tagify__input').text();
        var addedTags = new Array();

        tagify.settings.whitelist.length = 0;
        tagify.loading(true).dropdown.hide.call(tagify);

        if (typeof xhr == 'object') {
            xhr.abort();
            xhr = null;
        }

        if (tagify.value.length) {
            tagify.value.forEach(function (el, index) {
                addedTags.push(el.value);
            });

            addedTags = '&addedValues='+JSON.stringify(addedTags);
        }

        xhr = $.ajax({
            type: 'GET',
            url: '/contact/search?value='+encodeURIComponent(value)+addedTags,
            success: function(msg) {
                tagify.settings.whitelist.splice(0, msg.length, ...msg);
                tagify.loading(false).dropdown.show.call(tagify, value);
            }
        });
    });

    /** password generation */
    $('#generatePassword').on('click', function(e) {
        e.preventDefault();
        $('#channel_password').val(randomString(7)).trigger('change');
    });

    $('#channel_password').on('change keyup', function() {
        if ($(this).val().length >= 5) {
            $(this).removeClass('is-invalid');
            $(this).parents('.input-group:first').find('.invalid-feedback').remove();
        }
    });

    /** catch form submit */
    $('form[name="channel"]').on('submit', function(e) {
        e.preventDefault();

        if ($(this).data('submitted') == true) {
            return;
        }

        if (uppy.getFiles().length < 1) {
            $('#missingFilesModal').modal('show');
            return;
        }

        $(this).data('submitted', true);

        $('#channel_save').text('Bitte warten...').fadeOut();

        $.ajax({
            type: 'POST',
            data: $(this).serialize(),
            url: $(this).attr('action'),
            success: function(msg) {
                if (typeof msg.signUrl == 'undefined' || typeof msg.verifyUrl == 'undefined') {
                    raiseUploadError('Die Daten konnten nicht gespeichert werden (payload missing)');
                } else {
                    $('#metaDataContainer').find('input, textarea, select, .tagify').attr('readonly', true);
                    var uploadMeta = $('.upload-meta');
                    uploadMeta.data('sign-url', msg.signUrl);
                    uploadMeta.data('verify-url', msg.verifyUrl);
                    uploadMeta.trigger('upload');
                }
            },
            error: function(data) {
                raiseUploadError('Die Daten konnten nicht gespeichert werden (bad server response)');
            }
        });
    });
}

window.onload = function() {
    window.addEventListener("beforeunload", function (e) {
        if (!isUploading) {
            return undefined;
        }

        var confirmationMessage = 'Gerade findet noch eine Übertragung statt. '
                                + 'Wenn diese Seite geschlossen wird, gehen unvollständige Übertragungen verloren';

        (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
    });
};

var randomString = function(length)
{
    var charset = "ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghjkmnopqrstuvwxyz123456789";
    var i;
    var result = "";
    var values;
    if(window.crypto && window.crypto.getRandomValues)
    {
        values = new Uint32Array(length);
        window.crypto.getRandomValues(values);
        for(i=0; i<length; i++)
        {
            result += charset[values[i] % charset.length];
        }
        return result;
    }

    throw new Error("Your browser sucks and can't generate secure random numbers");
};

var raiseUploadError = function(msg) {
    uploadSuccess.removeClass('d-flex').addClass('d-none');
    uploadError.removeClass('d-none').addClass('d-flex').find('h4.text-danger').text(msg);
};